'use strict';


// another change

function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}
var Helpers = {
    cleanURI: function(uri) {
        if (uri === false || uri === '') {
            return false;
        }
        var uri = decodeURI(uri).split('+').join(' ');
        return uri;
    },
    getQueryVariable: function(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return (false);
    }
};


// Moved from bottom of the page to here so the product finder auto suggestion can work.
function fbAutoComplete() {
    console.log("collection: ", globals.funnelback.productsCollection);
   $("input.query").fbcompletion({
      'enabled'    : 'enabled',
      'standardCompletionEnabled': true,
      'collection' : globals.funnelback.productsCollection,
      'program'    : 'https://mabey2-search.squiz.cloud/search/../s/suggest.json',
      'format'     : 'extended',
      'alpha'      : '.5',
      'show'       : '10',
      'sort'       : '0',
      'length'     : '3',
      'delay'      : '0',
      'profile'    : '_default_preview',
      'query'      : '',
      //Search based completion
      'searchBasedCompletionEnabled': false,
      'searchBasedCompletionProgram': 'https://mabey2-search.squiz.cloud/search/../s/search.json',
    });  
}


// var UI = {
// 	settings: {
// 		collectionURL : globals.funnelback.searchURL || 'https://mabey2-search.squiz.cloud/s/search.html?profile=_default&form=json',
//         defaultParams: {
//             collection: globals.funnelback.productsCollection || 'products-uk',
//             num_ranks: 9,
//             //start_rank: Helpers.cleanURI(Helpers.getQueryVariable('start_rank')) || 1,
//             query: Helpers.cleanURI(Helpers.getQueryVariable('query')) || '!nullquery',
//             category: Helpers.cleanURI(Helpers.getQueryVariable('category')) || '',
//             application: Helpers.cleanURI(Helpers.getQueryVariable('application')) || '',
//             type: Helpers.cleanURI(Helpers.getQueryVariable('type')) || '',
//             sort: 'dmetaproductUpweight'
// 		},
// 		productFinderTemplate : globals['productFinderTemplateURL'] || 'product-finder-tpl.html',
//         productFinderResultsTemplate: globals['productFinderResultsTemplateURL'] || 'product-finder-results-tpl.html'
// 	},

// 	templates: {},
// 	resetFilter: function(filterName) {
// 		console.log('resetuj', filterName);
// 		$('#query-' + filterName).val('');
// 	},
//     pagination: function(totalCount, perPage) {
//         console.log(totalCount, 9);
//     },

//     getTemplate: function(templateName) {
//     	var url = templateName;
//     	var xhr = $.get(url, {site_id: globals.siteID}).then(function(data) {

//     		if(UI.templates[templateName] === undefined){
//     			UI.templates[templateName] = Handlebars.compile(data);
//     		}

//     	});
//     	return xhr;
//     },
//     getJSON: function(url, params) {
//     	return $.ajax(url, {
//     		dataType: 'jsonp',
//     		data: params
//     	})
//     },
//     init: function() {
//         $.when(
//         	this.getJSON(this.settings.collectionURL, this.settings.defaultParams),
//             this.getTemplate(this.settings.productFinderTemplate),
//             this.getTemplate(this.settings.productFinderResultsTemplate)
//         	)
//         	.then(function(results, template, resultsTemplate) {

//         		var json = results[0];
//         		var output = UI.templates[UI.settings.productFinderTemplate](json);

//                 // set next start part (offset)
//                 UI.settings.nextStart = json.resultsPagination.nextStart;

//         		UI.render(output, $('#product-finder-container'));

//                 fbAutoComplete();

//                 if(Helpers.getQueryVariable('query') || Helpers.getQueryVariable('category')) {
//                     if(Helpers.getQueryVariable('query') !== false) {
//                         $('#search-query').val(Helpers.cleanURI(Helpers.getQueryVariable('query')));
//                     }
//                     $('#results').show();
//                     $('#view-more-btn').show();
//                 }

//        		});
//        		// .fail(function(err) {
//        		// 	console.warn(err);
//        		// 	$('#product-finder-container').text(err.statusText);
//        		// })

//     },
//     reload: function() {

//         UI.$overlay.fadeIn();
//     	// get current params
//     	var params = {
//             collection: globals.funnelback.productsCollection || 'products-uk',
//             num_ranks: 9,
//     		category: $('#query-category').val(),
//     		application: $('#query-application').val(),
//     		type: $('#query-type').val(),
//             query: Helpers.cleanURI(Helpers.getQueryVariable('query')) || '!nullquery',
//             sort: 'dmetaproductUpweight'
//     	};

//         // set URL dynamically 
//         var categoryURL = '?category=' + $('#query-category').val();
//         var applicationURL = '&application=' + $('#query-application').val();
//         var typeURL = '&type=' + $('#query-type').val();
//         var query = Helpers.cleanURI(Helpers.getQueryVariable('query'));
//         // change URL
//         UI.currentURL = categoryURL + applicationURL + typeURL;
//         if(query) {
//             UI.currentURL = UI.currentURL + '&query=' + query;
//         }
//         window.history.replaceState('','', UI.currentURL);

//     	UI.getJSON(UI.settings.collectionURL, params).then(function(data) {
//     		var output = UI.templates[UI.settings.productFinderTemplate](data);
// 			UI.render(output, $('#product-finder-container'));
            
//             fbAutoComplete();
//             $("input.query").val(params.query);

//             $('#results').show();
//             if($('#view-more-btn').length) {
//                 $('#view-more-btn').show();
//             }
//             // dont show anything if category is not selected and there is no query
//             if(params.query === '!nullquery' && params.category === '') {
//                 $('#results').hide();
//                 $('#view-more-btn').hide();
//             }
            
//     	});
//     },
//     render: function(data, $container) {
//         $container.html(data);
//         UI.$overlay = $container.find('#results-overlay');
//         UI.$viewMoreBtn = $container.find('#view-more-btn');
//         $('#product-finder-container').find('select').selectric({
//             disableOnMobile: false,
//         	onBeforeChange: function(element) {
//                 if(element.id === 'query-category') {
//                     //reset other filters
//                     UI.resetFilter('application');
//                     UI.resetFilter('type');
//                 }
//                 else if(element.id === 'query-application') {
//                     UI.resetFilter('type');
//                 }
//             },
//         	onChange: UI.reload
//         });

//         UI.$viewMoreBtn.on('click', function() {
//             UI.viewMore();
//         });

//     },

//     viewMore: function() {
//         // show overlay
//         UI.$overlay.fadeIn();

//         var params = {
//             collection: globals.funnelback.productsCollection || 'products-uk',
//             num_ranks: 9,
//             category: $('#query-category').val(),
//             application: $('#query-application').val(),
//             type: $('#query-type').val(),
//             query: Helpers.cleanURI(Helpers.getQueryVariable('query')) || '!nullquery',
//             start_rank: UI.settings.nextStart,
//             sort: 'dmetaproductUpweight'
//         };

//         var xhr = UI.getJSON(this.settings.collectionURL, params);
//         xhr.done(function(data) {

//             //hide overlay
//             UI.$overlay.fadeOut();

//             // no other parts to show, hide view more button
//             if(data.pagination.hasOwnProperty('hasNext') === false) {
//                 $('#view-more-btn').hide();
//             }

//             // set next part start point (offset)
//             UI.settings.nextStart = data.resultsPagination.nextStart;

//             // append next part of results
//             $('#results').append(UI.templates[UI.settings.productFinderResultsTemplate](data));
//         });


//     }
// };

// (function($) {
//     if($('#product-finder-container').length) {
//         console.log('Product Finder INIT');
//         UI.init();
//     }

// }($));



//ProductFinder.init();