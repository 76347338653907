//helpers
Handlebars.registerHelper('grouped_each', function(every, context, options) {
    var out = "", subcontext = [], i;
    if (context && context.length > 0) {
        for (i = 0; i < context.length; i++) {
            if (i > 0 && i % every === 0) {
                out += options.fn(subcontext);
                subcontext = [];
            }
            subcontext.push(context[i]);
        }
        out += options.fn(subcontext);
    }
    return out;
});


Handlebars.registerHelper('ifBigger', function(v1, v2, options) {
    if(v1 > v2) {
      return options.fn(this);
    }
    return options.inverse(this);
});

Handlebars.registerHelper('ifEqual', function(v1, v2, options) {
  if(v1 === v2) {
    return options.fn(this);
  }
  return options.inverse(this);
});

Handlebars.registerHelper('decodeURI', function(str){
    return decodeURIComponent( str );
});

Handlebars.registerHelper('findInString', function(sourceStr, subString, options){
    if (sourceStr.indexOf( subString ) >= 0) {
        return options.fn(this);
    }
    
    return options.inverse(this);
});

Handlebars.registerHelper('getYoutubeId', function(link){
    var video_id = link.split('v=')[1];
    var ampersandPosition = video_id.indexOf('&');
    if(ampersandPosition != -1) {
      video_id = video_id.substring(0, ampersandPosition);
    }
    
    return video_id;
});

Handlebars.registerHelper('getVimeoId', function(link){
    return link.match(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/);
});




