    /*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * authors:  Arek Stasiewicz, Maciek Bilski [Squiz PL Szczecin]
 * change log:
 *
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
$('body').removeClass('no-js').addClass('js-enabled');

/*
* Mabey
*/
var Mabey = (function() {

    var $window = $(window);
    window.dataLayer = window.dataLayer||[]; 

    var Selectors = {
        // Body
        $bodyElem: $('html,body'),

        // buttons
        showMoreRelatedItemsButton: ".showMoreRelatedItems",
        buyHireBoxButtons: ".buy-hire-container__button",
        $shareButton: $('#button-share'),
        $showContactForm: $('#show-contact-form'),
        popupButton: '.ui-popup-button',
        mainMenuButton : ".nav__submenu-trigger",
        $toggleNavMobile: $('#toggle-nav-mobile'),
        headerToggleSearch: ".header__search-button-toggle-form",
        $acceptTechnicalLegalInfo: $('.js-accept-technical-legal-info'),
        // sliders
        $sliderContainer: $('.slider'), // tutaj moze jakies bardziej main slider czy cos?
        $sliderFor: $('.slider-for'),
        $sliderNav: $('.slider-nav'),
        $featuredProductsCarousel: $('.featured-products-carousel'),
        $customSliderWrapper: $('.custom-slider__wrapper'),
        $heroImageSlider: $('#homepage-hero-container__slider'),
        $capabilityImageSlider: $('#capability-hero-container__slider'),
        // sections, sticky nav
        $sectionNav: $('#sectionNav'),
        $sectionContent: $('section.content.in-sticky-nav'),
        $sectionNavElements: $('#sectionNav').find('ol'),

        //containers
        truncatedTextWrapper: ".is-truncated",
        truncatedTextContainer: ".truncated-text",
        $buyHireConatainer: $(".buy-hire-container"),
        $recentlyViewedContainer: $('#recently-viewed-container'),
        genericPageGooleMap: ".generic-content-map",
        $truncatedTextContainer: $(".truncated-text"),
        $mainNav: $('.nav'),
        headerSearchBar: ".header__search-bar",
        $searchResults: $('.search-results'),

        //Forms
        $customFormContainers: $('.custom-form-container'),
        $contactFormContainer: $('#contact-form-container'),

        //search filter
        $filterButton:$('#toggleSearchFilter'),
        $filterContainer:$('.search-filter'),

        //Location selector
        $locationSelect: $('.location-selector select'),

        //News select
        $newsSelect: $(".filter-by select"),
        $newsSelectList:(".selectric"),

        //contact Form
        inputContactId: '.js-custom-form-email-to',
        projectAddressFinder : '.js-contact-address-finder-input',

        //Homepage capabilities selector
        $capabilitiesSelect: $('.capabilities-select select'),
    };

    var PageType = $('#main-container').data('page-type');

/*
* Helpers
* everything we might use in other modules
*/
    var Helpers = {
        /*
        * scrollTo
        * @param elementId - id of element, preceeded by hash symbol
        * @param offset - number of px, default -100
        */
        scrollTo: function(elementId, offset) {
            var offset = offset || -100;
            Selectors.$bodyElem.animate({
                scrollTop: $(elementId).offset().top + offset
            }, 500);

        },


        /*
        * tableWrapper - wraps table onto responsive table div
        *
        */
        tableWrapper: function() {
            if ($(".content table[id^='table']")) {
                $(".content table[id^='table']").each(function(){
                    $(this).wrap('<div class="repsonsive-table"></div>');
                });
            }
        },

        selectricLauncher: function () {
            if ( $( '.selectric-here' ).length ) {
                $( '.selectric-here' ).selectric({
                    disableOnMobile: false
                });
            }
        },

        refreshWaypoints: function() {
            //refresh waypoints
            if (typeof(Waypoint) === 'function') {
                Waypoint.refreshAll();
            }
        },

        getCookie: function(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for(var i=0; i<ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1);
                if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
            }
            return "";
        },

        setCookie: function(name, value, exdays, path, domain, secure){
            if (exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays*24*60*60*1000));
                var expires = d.toUTCString();
            }
            var curCookie = name + "=" + escape(value) +
                ((expires) ? "; expires=" + expires : "") +
                ((path) ? "; path=" + path : "") +
                ((domain) ? "; domain=" + domain : "") +
                ((secure) ? "; secure" : "");
              document.cookie = curCookie;
        }
    };

/*
* dataCalls
* common ajaxed data
*/

    var dataCalls = {
        $funnelback : function(query) {
            return $.ajax({
                        url: globals.funnelback.searchURL,
                        type: "GET",
                        dataType:"jsonp",
                        data: query
                    });
        },
        $contactPolygons: function(lat, lng, radius) {
            return $.ajax({
                        url: globals.getPolygonsUrl,
                        type: "GET",
                        dataType:"json",
                        data: {lat: lat, lng: lng, radius: radius}
                    });
        },
        $userLocation: function() {
            return $.ajax({
                        url: globals.getLocataionUrl,
                        type: "GET",
                        dataType:"json"
                    });
        }
    };

    var Sliders = {

        // main image slider
        main: {
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: true,
                asNavFor: '.slider-nav',
                adaptiveHeight: false,
                nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
                prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>'
            }
        },

        // navigation for main image slider
        navForMain: {
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: false,
                centerMode: false,
                focusOnSelect: true,
                arrows: false
            }
        },
        heroImageSlider: {
          settings: {   
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              fade: true,
              adaptiveHeight: false,
              nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
              prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
              dots: true,
              customPaging: function(slick, index) {
                return '<button></button>';
              },
              autoplay: false,
              autoplaySpeed: 5000
          }
        },
        capabilitySlider: {
          settings: {   
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              fade: true,
              adaptiveHeight: false,
              nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
              prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
              dots: true,
              customPaging: function(slick, index) {
                return '<button></button>';
              },
              autoplay: false,
              autoplaySpeed: 5000
          }
        },
        customSliderWrapper: {
            settings: {
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        arrows: true
                    }
                }],

                nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
                prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
                adaptiveHeight: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: true,
                customPaging: function(slick, index) {
                    return '<button></button>';
                }
            }
        },
        featuredProductsCarousel: {
            settings: {
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        arrows: true
                    }
                }],

                nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
                prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
                adaptiveHeight: false,
                slidesToShow: 4,
                dots: false,
                arrows: true,
                // centerMode: true,
                infinite: true,
                customPaging: function(slick, index) {
                    return '<button></button>';
                },
            }
        },
        // mobile Nav settings
        mobileNav: {
            settings: {
                slidesToShow: 1,
                infinite: false,
                slidesToScroll: 1,
                speed: 100
                //fade: true

            }
        },

        ytPlayers: {}, //holds YT objects

        SlideManager: function() {
            $(document).on('click','.slick-next, .slick-prev', function(){
                $('.slider-nav .slick-slide').removeClass('slick-current');
                $('.slider-nav .slick-slide').eq($('.slider-for').slick('slickCurrentSlide')).addClass('slick-current');

            });
        },

        //ads youtube api for play and pause events
        ManageYoutube: function() {
            var playerId;
            $('.slider-for .video.youtube').on('click',function(e){
                $(this).addClass('is-active');
                playerId = $(this).find('iframe').attr('id');
                Sliders.ytPlayers[playerId] = new YT.Player(playerId, {
                    events: {
                      // call this function when player is ready to use
                      'onReady': onPlayerReady
                    }
                });

            });

            function onPlayerReady(event) {
                Sliders.ytPlayers[playerId].playVideo();
            }

            $('.slider-for').on('beforeChange', function(event, slick, currentSlide, nextSlide){
                var pId = $('.slider-for .slick-active .youtube iframe').attr('id');

                if (Sliders.ytPlayers[pId]) {
                    Sliders.ytPlayers[pId].pauseVideo();
                }
            });

            // Inject YouTube API script
            var tag = document.createElement('script');
            tag.src = "//www.youtube.com/player_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        },

        //ads vimeo api for play and pause events
        ManageVimeo: function() {
            var playerId;
            $('.slider-for .video.vimeo').on('click',function(e){
                $(this).addClass('is-active');
                playerId = $(this).find('iframe').attr('id');
                var iframe = document.getElementById(playerId);

                // $f == Froogaloop
                var player = $f(iframe);

                player.api("play");

            });

            $('.slider-for').on('beforeChange', function(event, slick, currentSlide, nextSlide){
                var pId = $('.slider-for .slick-active .vimeo iframe').attr('id');
                var iframe = document.getElementById(pId);

                // $f == Froogaloop
                var player = $f(iframe);
                player.api("pause");
            });

            // Inject YouTube API script
            var tag = document.createElement('script');
            tag.src = "//f.vimeocdn.com/js/froogaloop2.min.js";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    };

/*
* Button Manager
*/
    var buttonManager = {

        showMoreRelatedItems: function() {

            if ($(Selectors.showMoreRelatedItemsButton)) {
                $(document).on('click touch', Selectors.showMoreRelatedItemsButton, function() {
                    var $target = $($(this).data('target'));
                    $target.removeClass('mobile-related-show-first-item');
                    $target.find( Selectors.showMoreRelatedItemsButton ).remove();
                    if ($target.find('.js-view-more-button')) {
                        $target.find('.js-view-more-button')
                                .removeClass('hidden-xs')
                                .removeClass('hidden-from-sm');
                    }
                });
            }
        },

        // shwotruncatedText: function() {
        //     if ( $(Selectors.truncatedTextReadMoreButton) ) {
        //         $(document).on('click touch', Selectors.truncatedTextReadMoreButton, function() {
        //             var $truncatedTextWrapper = $(this).parents(Selectors.truncatedTextWrapper);
        //             $truncatedTextWrapper.find(Selectors.truncatedTextContainer).remove();
        //             $truncatedTextWrapper.removeClass('is-truncated');

        //         });
        //     }
        // },

        buyHireBoxManger: function() {
            if ($(Selectors.buyHireBoxButtons)) {
                $(document).on('click touch', Selectors.buyHireBoxButtons, function() {
                    if ( !$(this).hasClass('is-active') ) {
                        var contactId = $( $(this).data('target') ).find('.buy-hire-container__person').data('contact');
                        Selectors.$contactFormContainer.attr('data-contact-email', contactId);
                        $( Selectors.inputContactId ).val(contactId);
                        Selectors.$contactFormContainer.removeClass('is-active');

                        Selectors.$buyHireConatainer.find('.is-active').removeClass('is-active');
                        $(this).addClass('is-active');
                        $($(this).data('target')).addClass('is-active');
                        Selectors.$contactFormContainer.find( Selectors.projectAddressFinder ).val('');
                    }
                });
            }
        },

        headerPopupToggle: function() {
            if ($(Selectors.popupButton)) {

                $(document).on('click touch', Selectors.popupButton, function(e) {
                    var $element = $( this ).parent('.ui-popup-button-wrapper');

                    if ($element.hasClass('is-active')) {
                        $element.removeClass('is-active');
                    }
                    else {
                        $( '.js-body-click.is-active' ).removeClass('is-active');
                        $element.addClass('is-active');
                    }

                    e.stopPropagation();
                });
            }
        },

        mainMenuToggle: function() {
            $('.nav__first-level-item').removeClass('show-on-hover');
            $(document).on('click touch',  Selectors.mainMenuButton, function(e) {
                var $element = $( this ).parents('.nav__first-level-item');

                if ($element.hasClass('is-active')) {
                    $element.removeClass('is-active');
                } else {
                    $( '.js-body-click.is-active' ).removeClass('is-active');
                    $element.addClass('is-active');
                }

                e.stopPropagation();
            });
        },

        mainMenuToggleMobile: function() {
            Selectors.$toggleNavMobile.on('click touch', function() {
                Selectors.$mainNav.slideToggle();

                // hamburger
                var $icon = $(this).find('i');
                if($icon.hasClass('icon-justify')) {
                    $icon.removeClass('icon-justify').addClass('icon-cross');
                }
                else {
                    $icon.removeClass('icon-cross').addClass('icon-justify');
                }
                // remove searchbar if displayed
                $( Selectors.headerSearchBar ).removeClass('is-active');
                return false;
            });
        },

        //swtch beetween microsites
        switchLocation: function() {
            Selectors.$locationSelect.change(function(){
                Helpers.setCookie("country", $( this ).val(), 365, "/", "." + window.location.hostname);
                window.location.href = globals.countryMapper[$( this ).val()];
            });
        },

        //close elements on body click
        bodyClick: function() {
            $( document ).on('click touch', function(e) {
                if (!$(e.target).is('.js-body-click, .js-body-click *, body')) {
                    $( '.js-body-click' ).removeClass('is-active');
                }

            });
        },

        truncation: function() {
            Selectors.$truncatedTextContainer.each(function() {
                var $this = $(this);
                var splitedText = $this.html().split('<hr>');
                if (splitedText.length > 1) {
                    var $excerpt = splitedText[0];
                    var newText = '<div class="exceprt">' + splitedText[0] + '</div><a class="read-more-link" href="#">Read more</a><div class="rest-entry" style="display:none">' + splitedText[1] + '</div>';

                    $this.html(newText).show();
                    $this.on('click touch', '.read-more-link', function(e) {
                        $('.rest-entry').show();
                        $(this).hide();
                        return false;
                    });
                }
            });

        },

        headerSearchToggle: function() {
            $( document ).on('click touch', Selectors.headerToggleSearch, function(e) {
                e.preventDefault();
                var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                if(windowWidth < 768) {
                    Selectors.$mainNav.slideUp();
                }
                $( Selectors.headerSearchBar ).toggleClass('is-active');
            });
        },

        acceptTechnicalLegalInfo: function() {

            if (Helpers.getCookie("legalInfoAccept")) {
                $('.technical-library-legal-info').remove();
                $('.technical-library-container').removeClass('hidden');

                Navigation();

            } else {
                $(Selectors.$acceptTechnicalLegalInfo).on('click touch', function(){
                    if( $(this).data('collect') &&
                            !Helpers.getCookie('data_collected')) {

                        docDowloadManager.skipInfo($(this));
                        docDowloadManager.docPopupForm($(this));
                    }
                    else {
                        docDowloadManager.docPopupFormClose($(this));
                    }
                });
            }
        },

        homePageHeroVideoPlayer : function() {
            $('.js-slider-play-video-modal').magnificPopup({
                type: 'iframe',
                iframe: {
                    markup: '<div class="mfp-iframe-scaler">'+
                              '<div class="mfp-close"></div>'+
                              '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

                    patterns: {
                      youtube: {
                        index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                        id: 'v=', // String that splits URL in a two parts, second part should be %id%
                        // Or null - full URL will be returned
                        // Or a function that should return %id%, for example:
                        // id: function(url) { return 'parsed id'; }

                        src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0' // URL that will be set as a source for iframe.
                      },
                      vimeo: {
                        index: 'vimeo.com/',
                        id: '/',
                        src: '//player.vimeo.com/video/%id%?autoplay=1'
                      }
                    },

                    srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
                  }
            });

            Selectors.$heroImageSlider.on('click', function(){
                $(this).slick('slickPause');
            });
        },

        // Capabilities landing  - product list show/hide
        // productListToggle: function () {
        //     $('.toggle-product-list').click(function() {
        //         $(this).next().toggleClass("is-active", 1000);
        //         return false;
        //     });
        // },

        //Capabitlity select from homepage page
        goToCapabilityPage: function() {
            Selectors.$capabilitiesSelect.change(function(){
                window.location.href = $(this).val();
            });
        },

    };

/*
* Navigation
* handle sticky navigation and smooth scrolling
*/
    var Navigation = function() {

        if (!Selectors.$sectionContent) {
            Selectors.$sectionNav.remove();
            return false;
        }

        // smooth scrolling
        Selectors.$sectionNav.find('a').click(function(event) {

            Helpers.scrollTo($.attr(this, 'href'), -100);
            event.preventDefault();

        });

        // stickyNav
        if(Selectors.$sectionNav.length) {
            var stickyNav = new Waypoint.Sticky({
                element: Selectors.$sectionNav
            });
        }

        // sticky Share Button
        if(Selectors.$shareButton.length) {
            var stickyShareBtn = new Waypoint.Sticky({
                element: Selectors.$shareButton,
                offset: -(stickyNav.wrapper.offsetTop - Selectors.$shareButton.offset().top)
            });
        }

        // Waypoints
        if(Selectors.$sectionContent.length) {

            Selectors.$sectionContent.waypoint({
                handler: function(direction) {
                    if (direction === 'down') {
                        Selectors.$sectionNav.find('.is-active').removeClass('is-active');
                        $('#'+this.element.id+'-link').addClass('is-active');

                        // set navigation slider on current waypoint section
                        var currentSectionIndex = Selectors.$sectionContent.index( $('#' + this.element.id) );
                        if(Selectors.$sectionNavElements.hasClass('slick-initialized')) {
                            Selectors.$sectionNavElements.slick('slickGoTo', currentSectionIndex, true);
                        }
                    }
                },
                offset: 300
            });

            Selectors.$sectionContent.waypoint({
                handler: function(direction) {
                    if (direction === 'up') {
                        Selectors.$sectionNav.find('.is-active').removeClass('is-active');
                        $('#'+this.element.id+'-link').addClass('is-active');

                        // set navigation slider on current waypoint section
                        var currentSectionIndex = Selectors.$sectionContent.index( $('#' + this.element.id) );
                        if(Selectors.$sectionNavElements.hasClass('slick-initialized')) {
                            Selectors.$sectionNavElements.slick('slickGoTo', currentSectionIndex, true);
                        }
                    }
                },
                offset: -80
            });
        };

        var mobileNavInit = function() {

            var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if(windowWidth < 768) {

                // MOBILE VIEW STARTS HERE
                //console.log('Mobile view initialized');


                // turn off desktop nav menu leveling
                // Selectors.mainMenuButton.off('click touch');

                // init slickslider
                if(!Selectors.$sectionNavElements.hasClass('slick-initialized')) {
                    Selectors.$sectionNavElements.slick(Sliders.mobileNav.settings);
                }
            }
            else {

                // NON-MOBILE VIEW
                if(Selectors.$sectionNavElements.hasClass('slick-initialized')) {
                    // try to remove only when initialized
                    Selectors.$sectionNavElements.slick('unslick');
                }
            }

        };


        $window.resize(function() {            
            debounce(mobileNavInit(), 1000);
        });

        mobileNavInit();

    };

    // Recently Viewed - requires store.min.js
    // @params assetId, URL - from globals obj (printed in parse file)

    var recentlyViewed = function(assetId, recentlyViewedListingURL) {
        if(store && store.enabled) {
            var _arr = store.get('recentlyViewed') || [];
            if(assetId !== undefined && _arr.indexOf(assetId) === -1) {
                if(PageType === 'product_page') {
                    _arr.push(assetId);
                }
            }
            store.set('recentlyViewed', _arr);

            //try to load
            if(store.get('recentlyViewed').length && recentlyViewedListingURL !== undefined) {
                var data = store.get('recentlyViewed').reverse().join(',');
                var _xhr = $.get(recentlyViewedListingURL, {ids: data, site_id: globals.siteID});
                _xhr.done(function(data) {
                    Selectors.$recentlyViewedContainer.html( data );
                })
            }
            else {
                // no container to load
            }

        }
        else {
            console.warn('store.js not enabled or localstorage not supported');
        }

    };

    // Google map on generic page - requires google maps API

    var genericPageMap = function() {

            $(Selectors.genericPageGooleMap).each(function() {
                var marker;
                var map = new google.maps.Map(document.getElementById( $(this).attr('id') ), {
                    styles: [ { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "visibility": "off" }] },{ "featureType": "administrative.locality", "elementType": "geometry", "stylers": [ { "visibility": "off" } ] },{ "featureType": "administrative.neighborhood", "stylers": [ { "visibility": "off" } ] },{ "featureType": "administrative.land_parcel", "stylers": [ { "visibility": "off" } ] },{ "featureType": "water", "stylers": [ { "visibility": "on" }, { "color": "#f4f0eb" } ] },{ "featureType": "administrative.country", "elementType": "labels", "stylers": [ { "visibility": "off" } ] },{ "featureType": "administrative.country", "elementType": "geometry.stroke", "stylers": [ { "color": "#ded3c4" } ] },{ "featureType": "transit", "stylers": [ { "visibility": "off" } ] },{ "featureType": "landscape", "stylers": [ { "color": "#e9e0d6" } ] },{ "featureType": "poi", "stylers": [ { "visibility": "off" } ] },{ "featureType": "administrative.province", "stylers": [ { "visibility": "off" } ] } ],
                    streetViewControl: false,
                    mapTypeControl: false,
                    minZoom: 2,
                    zoom: 15,
                    scrollwheel: false,
                    center: {lat: parseFloat($(this).data('lat')), lng: parseFloat($(this).data('lon')) }
                });

                 marker = new google.maps.Marker({
                        map: map,
                        animation: google.maps.Animation.DROP,
                        position: {lat: parseFloat($(this).data('lat')), lng: parseFloat($(this).data('lon')) },
                        icon: $(this).data('marker'),
                        visible:true
                    });

            });
    };

    var ContactForm = function() {

        // show/hide modal
        Selectors.$showContactForm.on('click', function() {
            //Selectors.$contactFormContainer.fadeToggle(200);
            Selectors.$contactFormContainer.toggleClass('is-active');

            if ($(this).parents('.buy-hire-container')) {
                //launch geodecode
                $( '.js-contact-address-finder-input' ).geocomplete();
                $( '.js-contact-address-finder-input' ).trigger("geocode").bind("geocode:result", function(event, result){
                    Selectors.$contactFormContainer.find('.sq-form-submit').attr('disabled', 'disabled');
                    getContactPolygons(result.geometry.location.lat(),result.geometry.location.lng(), 1);
                });

                //Apply user details form geo-location
                addUserLocationInfo();
            }

            return false;
        });

        var addUserLocationInfo = function() {
            var textAreaVal = '';

            if (globals.locationDetails) {
                if (globals.locationDetails.continent) {
                    textAreaVal += 'Continent: ' + globals.locationDetails.continent + '\n';
                }

                if (globals.locationDetails.country) {
                    textAreaVal += 'Country: ' + globals.locationDetails.country + '\n';
                }

                if (globals.locationDetails.city) {
                    textAreaVal += 'City: ' + globals.locationDetails.city + '\n';
                }
            }

            if (textAreaVal) {
                $('.js-contact-form-user-location').find('textarea').val(textAreaVal);
            }
            else {
                $('.js-contact-form-user-location').find('textarea').val('User Location not defined');
            }
        };

        //getting contact polygons
        var getContactPolygons = function(lat, lng, radius) {

            var getData = dataCalls.$contactPolygons(lat, lng, radius);

            getData.done(function(data) {
                if (data) {
                    var fbQuery = 'num_ranks=1&collection=' + globals.funnelback.contactsCollection,
                        categories = Selectors.$buyHireConatainer.data('categories'),
                        i;

                    if ($('#buyLHS').hasClass('is-active')) {
                        fbQuery += '&meta_contactBuyHire=2';
                    }
                    else if ($('#hireLHS').hasClass('is-active'))  {
                        fbQuery += '&meta_contactBuyHire=1';
                    }

                    //building query
                    for (i=0; i<data.length; i++) {
                        fbQuery += '&meta_contactLocationAreaAreaIds_or=' + data[i];
                    }

                    for (i=0; i<categories.length; i++) {
                        fbQuery += '&meta_contactApplicationIds_or=' + categories[i];
                    }

                    getContactEmail(fbQuery);
                }
                else {
                    if (radius>10000000) {
                        return false;
                    }
                    getContactPolygons(lat, lng, radius + 500000);
                }
            });
        };

        var getContactEmail = function(query) {
            var getUserData = dataCalls.$funnelback(query);

            getUserData.done(function(fbData) {
                Selectors.$contactFormContainer.find('.sq-form-submit').removeAttr('disabled');
                    if (fbData
                            && fbData.hasOwnProperty('results')
                            && fbData.results.length
                            && fbData.results[0].assetID) {
                        Selectors.$contactFormContainer.data('contact-email', fbData.results[0].assetID)
                        $( Selectors.inputContactId ).val(fbData.results[0].assetID);
                    }
            });
        };
    };

    var CustomForms = function() {
        var sel = {
            requiredFieldContainer : ".custom-form__error-message",
            requiredFieldMessage: '<p class="sq-form-error">This field is required. Please enter a value.</p>',
            invalidEmailMessage: '<p class="sq-form-error">Please enter an email address</p>'
        };

        // handle forms
        $('.custom-form').on('submit', 'form', function(e) {
            var $form = $(this);

            //front-end validation if possible
            var requiredFileds = $form.find('.is-required');
            var requiredFieldsLength = requiredFileds.length;
            var valid = true;
            if(requiredFieldsLength) {
                var i, $field;
                for (i = 0; i<=requiredFieldsLength; i++) {
                    $field = $(requiredFileds[i]);

                    if($field.val() === '') {
                        $field.addClass('required-field');
                        valid = false;
                        $field.next( sel.requiredFieldContainer ).html( sel.requiredFieldMessage );
                    }
                    else if ($field.attr('type') === 'email') {
                        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if ( !re.test( $field.val() ) ) {
                            $field.addClass('required-field');
                            $field.next( sel.requiredFieldContainer ).html( sel.invalidEmailMessage );
                            valid = false;
                        } else {
                            $field.removeClass('required-field');
                            $field.next( sel.requiredFieldContainer ).html('');
                        }
                    }
                    else {
                        $field.removeClass('required-field');
                        $field.next( sel.requiredFieldContainer ).html('');
                    }
                }
            }

            var formLocation = $( ".custom-form-container" ).attr( "data-form-url" );
            var formCategory = formLocation.substring(formLocation.lastIndexOf('/') + 1);

             if (!valid) {
                window.dataLayer.push({'event':'form error','formCategory':formCategory,'formTitle':document.title,'eventCategory':'Form Error'});
                return false;
             }

            //overlay
            var $overlay = $('<div class="form-overlay" style="display:none;"><span></span></div>');
            $form.append($overlay);
            $overlay.fadeIn(200);

            // matrix needs submit value to be sent...
            var clonedSubmit = $form.find('.sq-form-submit').clone();
            $form.append(clonedSubmit.attr('type', 'hidden'));

            $(this).ajaxSubmit({

                success: function(response) {
                    
                    $overlay.fadeOut(200);
                    $form.html(response);
                    //apply selectric
                    $form.find('select').selectric();
                    $('.sq-form-error').siblings('.sq-form-question-answer').find('.sq-form-field').addClass('required-field');
                    Helpers.refreshWaypoints();
                    if(!$('.sq-form-error').length) {
                       window.dataLayer.push({'event':'form submit','formCategory':formCategory,'formTitle':document.title,'eventCategory':'Form Submit'});
                    }
                    else {
                        window.dataLayer.push({'event':'form error','formCategory':formCategory,'formTitle':document.title,'eventCategory':'Form Error'});   
                    }

                    //console.log('popup succsess msg', $form.find('#popup-success-msg').length);
                    if($form.find('#popup-success-msg').length === 1) {
                        Selectors.$contactFormContainer.delay(2000).fadeOut(200, function(){
                            Selectors.$contactFormContainer.removeClass('is-active');
                            Selectors.$contactFormContainer.removeAttr('style');
                        });
                    }
                }
            });
            return false;
        });

        // load form and render it
        var loadForm = function($container, url) {
            var xhr = $.ajax({
                url: url,
                data: {
                    SQ_DESIGN_NAME: 'blank',
                    SQ_PAINT_LAYOUT_NAME: 'blank',
                    language_code: globals.languageCode,
                    site_id: globals.siteID,
                    asset_id: globals.assetID
                }
            });

            xhr.done(function(data) {
                $container.html(data);
                //apply selectric
                $container.find('select').selectric();

                // add phone hide/show field toggle
                $container.find('#contact-form-phone-field-toggle').on('click', function() {
                    $container.find('#contact-form-phone-field').toggleClass('hidden');
                });

                // close button
                $container.find('#contact-form-popup-close-btn').on('click', function(e) {
                    e.preventDefault();
                    Selectors.$contactFormContainer.removeClass('is-active');

                });

                // if email defined, add hidden input with it (used in main contact form)
                var contactEmail = $container.data('contact-email');
                $(Selectors.inputContactId).val(contactEmail);

                //refresh waypoints
                Helpers.refreshWaypoints();
            });
        };

        // load all forms
        // var customForms = Selectors.$customFormContainers;
        var customFormsLength = Selectors.$customFormContainers.length;
        var i = 0;

        for (i = 0; i <= customFormsLength; i++) {

            var $container = $(Selectors.$customFormContainers[i]);
            var formURL = $container.data('form-url');
            if (formURL !== undefined) {
                loadForm($container, formURL);
            }
        }
    };

    var toggleFilter = function() {
        //toggle search filter
        $(Selectors.$filterButton).on('click', function(){
            $(Selectors.$filterContainer).toggleClass('is-active');
            $(this).toggleClass('is-active');
           return false;
        });


    };

    var newsFilter = function() {
        $(Selectors.$newsSelect).selectric({
            disableOnMobile: false
        });
    };

    //getting contact box
    var getContactBoxInfo =  function() {
        //getting user coordinate
        //var getUserLocation = dataCalls.$userLocation();

        var getUserPolygons = function(location) {

            if (location) {
                var getContactPolygons = dataCalls.$contactPolygons(location.latitude, location.longitude, 1);

                getContactPolygons.done(function(data) {
                    if (data) {
                        var query = 'num_ranks=1&collection=' + globals.funnelback.contactsCollection,
                            i,
                            contactType = Selectors.$buyHireConatainer.data('contacts'),
                            categories = Selectors.$buyHireConatainer.data('categories'),
                            categoryQuery = '',
                            locationQuery = '';

                        //building query
                        for (i=0; i<categories.length; i++) {
                            categoryQuery += '&meta_contactApplicationIds_or=' + categories[i];
                        }

                        for (i=0; i<data.length; i++) {
                            locationQuery += '&meta_contactLocationAreaAreaIds_or=' + data[i];
                        }
                        
                        switch (contactType) {
                            //hire contact
                            case 1:
                                //check if manual results is not set
                                if ( !$('#buyLHS').hasClass('js-manual') ) {
                                    getContact(query + '&meta_contactBuyHire=2', locationQuery, categoryQuery, 2);
                                }
                                
                                $('#buyLHS').addClass('is-active');
                                $('#hireLHS').removeClass('is-active');
                            break;
                            
                            //Buy contact
                            case 2:
                                //check if manual results is not set
                                if ( !$('#hireLHS').hasClass('js-manual') ) {
                                    getContact(query + '&meta_contactBuyHire=1', locationQuery, categoryQuery, 1);
                                }
                            break;

                            case 12:
                                //check if manual results is not set
                                if ( !$('#hireLHS').hasClass('js-manual') ) {
                                    getContact(query + '&meta_contactBuyHire=1', locationQuery, categoryQuery, 1);
                                }
                                
                                //check if manual results is not set
                                if ( !$('#buyLHS').hasClass('js-manual') ) {
                                    getContact(query + '&meta_contactBuyHire=2', locationQuery, categoryQuery, 2);
                                }
                            break;
                        }
                    }
                    else {
                        showContact();
                    }
                });

                getContactPolygons.fail(function(){
                    showContact();
                });
            }
            else {
               showContact();
            }

        };

        //getting contact box information
        var getContact = function(initalQuery, locationQuery, categoryQuery , contactType) {
            var query = initalQuery + locationQuery + categoryQuery;

            var fbData = dataCalls.$funnelback(query);

            fbData.done(function(fbData) {
                if (fbData
                        && fbData.hasOwnProperty('results')
                        && fbData.results.length) {

                    //handlebars template
                    var tamplate = Handlebars.compile( $('#contactBox-template').html() );
                    var html = tamplate(fbData.results[0]);

                    if (contactType === 2) {
                        $('#buyLHS').html(html);
                    }
                    else if (contactType === 1) {
                        $('#hireLHS').html(html);
                    }

                    showContact();
                }
                //else if (locationQuery) {
                //    getContact(initalQuery, '', categoryQuery , contactType);
                //}
                else {
                    showContact();
                }
            });

            fbData.fail(function(){
                showContact();
            });
        };

        var showContact = function() {
            Selectors.$buyHireConatainer.find('.js-hidden-contacts').removeClass('hidden');
            Selectors.$buyHireConatainer.find('.loader-overlay--circle').remove();
        };

        // SDUKP-3878
        /* var getUserCoordinates = function() {
            var onSuccess = function(location) {

                var locationObj = {
                    "IP" : location.traits.ip_address,
                    "latitude" : location.location.latitude,
                    "longitude" : location.location.longitude,
                    "country" : location.country.names.en,
                    "city" : location.city.names.en,
                    "continent" : location.continent.names.en
                };
                
                //temporary switced off - clients request
                //if (checkIgnoredCoordinates(locationObj)) {
                    if(store && store.enabled) {
                        store.set('userLocation', locationObj);
                    }

                    Helpers.setCookie("locationSet", '1', 0, "/", "." + window.location.hostname);
                    globals.locationDetails = locationObj;
                    getUserPolygons(locationObj);
                //}
            };

            var onError = function() {
                globals.locationDetails = false;
            };

            geoip2.city(onSuccess, onError);
        }; */

        // SDUKP-3878
        var getUserCoordinates = function () {
            var xhr = new XMLHttpRequest();
            var url = globals.geoIP;
            xhr.open('GET', url);
          
            var onSuccess = function (location) {
              var locationObj = {
                IP: location.ip,
                latitude: location.latitude,
                longitude: location.longitude,
                country: location.country_name,
                city: location.city,
                continent: location.continent_name,
              };
              //temporary switced off - clients request
              //if (checkIgnoredCoordinates(locationObj)) {
              if (store && store.enabled) {
                store.set('userLocation', locationObj);
              }
              Helpers.setCookie(
                'locationSet',
                '1',
                0,
                '/',
                '.' + window.location.hostname
              );
              globals.locationDetails = locationObj;
              getUserPolygons(locationObj);
              //}
            };
            var onError = function () {
              globals.locationDetails = false;
            };
          
            xhr.onload = function () {
              if (xhr.status !== 200) {
                // analyze HTTP status of the response
                onError();
                console.log('Error ' + xhr.status + ': ' + xhr.statusText); // e.g. 404: Not Found
              } else {
                // show the result
                onSuccess(JSON.parse(xhr.response));
              }
            };
          
            xhr.send();
        };
          
        //temporary switced off - clients request
        var checkIgnoredCoordinates = function(locationObj) {
            for (var key in globals.ignoredCoordinates) {
                ignoredLocation = globals.ignoredCoordinates[key].split(',');

                if (parseFloat(locationObj.latitude) === parseFloat(ignoredLocation[0]) &&  parseFloat(locationObj.longitude) === parseFloat(ignoredLocation[1])) {
                    showContact();
                    return false;
                }
            }

            return true;
        };

        var locationObj;
        
        if ( Selectors.$buyHireConatainer.find('.js-manual').length !== Selectors.$buyHireConatainer.data('contacts-count') ) {
            if(store && store.enabled) {
                locationObj = store.get('userLocation') || false;

                var locationSet = Helpers.getCookie("locationSet");
                var ignoredLocation;

                if (locationObj
                        && locationSet) {

                    //temporary switced off - clients request
                    //if (checkIgnoredCoordinates(locationObj)) {
                        globals.locationDetails = locationObj;
                        getUserPolygons(locationObj);
                    //}
                }
                else {
                    getUserCoordinates();
                }
            }
            else {
                getUserCoordinates();
            }
        } else {
            showContact();
        }
    };

    var searchPage = function() {
        var sel = {
            filtersSelect : ".filters-select",
            searchResultsContainer : "#search-results",
            showMoreSearchResults: ".show-more-search-results"
        };

        $( document ).on('change', sel.filtersSelect , function() {

            var query = {
                type: $(this).val(),
                query: $( this ).data('query'),
                collection: globals.funnelback.globalSearchCollection,
                site: globals.siteID,
                updateFilters: $(this).val() ? '' : true
            };

            var result = updateResults(query);

            result.done(function(html){
                if ( query.type ) {
                    $(sel.searchResultsContainer).html(html);
                }
                else {
                    Selectors.$searchResults.html(html);
                    Helpers.selectricLauncher();
                }
            });
        });

        $( document ).on('click touch', sel.showMoreSearchResults , function() {

            var query = {
                type: $( sel.filtersSelect ).val(),
                query: $( this ).data('query'),
                collection: globals.funnelback.globalSearchCollection,
                start_rank: $( this ).data('start'),
                site: globals.siteID
            };

            $( this ).remove();

            var result = updateResults(query);

            result.done(function(html){
                $(html).appendTo(sel.searchResultsContainer);
            });

        });

        var updateResults = function(query) {

            var $loader = Selectors.$searchResults.find('.loader-overlay');
            $loader.addClass('is-visible');

            return $.ajax({
                url: globals.globalSearchResults,
                type: "GET",
                dataType:"html",
                data: query,
                success: function() {
                    $loader.removeClass('is-visible');
                }
            });
        };

    };

    var newsAggregator = function() {
        var sel = {
            filtersSelect : ".checkbox-container input",
            searchResultsContainer : "#news-results",
            showMoreSearchResults: ".js-show-more-news-results",
            resultsScope: '.js-resutls-scope:checked',
            $searchResults: $('.search-results')
        };


        var buildQuery = function(reset, resetMedia, nextPage) {
            var scope =  $(sel.resultsScope).val() ? $(sel.resultsScope).val() : '';
            var collection = globals.funnelback.newsAndMedia;
            var nextPage =  nextPage;
            var query = {
                collection: collection,
                site: globals.siteID,
                updateFilters: reset ? true : '',
                showTweetResults: true,
                scope: scope,
                countryCode: globals.languageCode,
                asset: globals.assetID
            };

            if ($(".checkbox-container--selectable input:checked").length > 0) {
                const activeItems = $(".checkbox-container--selectable input:checked");
 
                $(activeItems).each(function(i) {
                       if (query[$(this).data('filter')] && query[$(this).data('filter')].length > 0) {
                        query[$(this).data('filter')] = query[$(this).data('filter')] + ',' + encodeURIComponent($(this).attr('name'));
                    } else {
                        query[$(this).data('filter')] = encodeURIComponent($(this).attr('name'));
                    }
                });
            }

            if ($(".checkbox-container--reset input:checked").length > 0) { 
                const activeItems = $(".checkbox-container--reset input:checked");
                if (resetMedia) {
                    $(activeItems).each(function(i) {
                        query[$(this).data('filter')] = '';
                    });
                }
            }

            if ($('#news-type').val() && !reset) {
                query.num_ranks = 5;
                query.showTweetResults = false;
                query["media-type"] = $('#news-type').val();

            }
            else {
               query.num_ranks = 5;
               query.meta_pageType_not='Twitter';
            }

            if ($('#news-year').val() && !reset) {
                query.year = $('#news-year').val();
            }

            if (nextPage) {
                query.start_rank = nextPage;
            }

            var result = updateResults(query);

            result.done(function(html){
                if (nextPage) {
                    $('.related-item__view-more-container').remove();
                }
                if ( query.updateFilters ) {
                    $('.search-filter-container').remove();
                    $('.news-media-container').remove();
                    sel.$searchResults.prepend(html);
                } else {
                    $(html).appendTo(sel.searchResultsContainer);
                }
            });
        };

        $( document ).on('change', sel.filtersSelect , function(e) {
            buildQuery(true, $(this).data('reset'));

        });

        $( document ).on('click touch', sel.showMoreSearchResults , function(e) {
            buildQuery(false, $(this).data('reset'), $( this ).data('start'));            
            $( this ).remove();
        });

        $( document ).on('change', '.js-resutls-scope' , function() {
            buildQuery(true);
        });

        var updateResults = function(query) {

            var $loader = sel.$searchResults.find('.loader-overlay');
            $loader.addClass('is-visible');

            return $.ajax({
                url: globals.newsAndMediaAggr,
                type: "GET",
                dataType:"html",
                data: query,
                success: function() {
                    $loader.removeClass('is-visible');
                }
            });
        };
    };

    var cookieInfo = function() {
        var sel = {
            acceptCookieButton : '#accept-cookie',
            $cookieMessageContainer: $('.cookie-message')
        };

       var cookieAccept = Helpers.getCookie('cookies_info');
       if (cookieAccept) {
           sel.$cookieMessageContainer.remove();
       } else {
            sel.$cookieMessageContainer.show();
            $( document ).on('click touch', sel.acceptCookieButton, function() {
                Helpers.setCookie("cookies_info", '1', 365, "/", "." + window.location.hostname);
                sel.$cookieMessageContainer.remove();
            });
       }
    };

    var docDowloadManager = {
        sel: {
            downloadLink: '.js-document',
            $acceptDisclaimerButtom: $(".js-accept-technical-legal-info-popup"),
            skipFormButton: '.skip-form'
        },

        init: function() {
            var _self = this;
            $( document ).on('click touch', this.sel.downloadLink, function(e) {

                //show this only if legal desclimer is not needed or accepted
                if ($(this).data('collect')
                        && !Helpers.getCookie('data_collected')
                        && (!_self.sel.$acceptDisclaimerButtom.length || !$(this).data('accept') || ($(this).data('accept') && Helpers.getCookie('data_accepted')) ) ) {
                    e.preventDefault();
                    e.stopPropagation();

                    _self.skipInfo( $(this) );

                    _self.docPopupForm( $(this) );
                }

                //show legal disclaimer
                if ($(this).data('accept') && !Helpers.getCookie('data_accepted') && _self.sel.$acceptDisclaimerButtom.length) {
                    e.preventDefault();
                    e.stopPropagation();
                    var $link = $(this);
                    _self.docPopupInfo();
                    _self.sel.$acceptDisclaimerButtom.on('click touch', function() {
                        $.magnificPopup.close();
                        Helpers.setCookie("data_accepted", '1', 365, "/", "." + window.location.hostname);
                        window.open($link.attr('href'));
                    });
                }
            });
        },

        skipInfo : function($element) {
             var $element = $element;
             var _self = this;

            $( document ).on('click touch', this.sel.skipFormButton, function(e) {
                e.preventDefault();
                e.stopPropagation();
                $.magnificPopup.close();
                Helpers.setCookie("data_collected", '1', 0, "/", "." + window.location.hostname);
                _self.docPopupFormClose($element);
            });
        },

        docPopupInfo : function() {

            $.magnificPopup.open({
                items: {
                  src: "#disclaimer-popup-info"
                },
                mainClass: "mfp-doc-popup",
                type: 'inline'
              }, 0);
        },

        docPopupForm : function( $element ) {
            var $link = $element;
            var _self = this;
            $.magnificPopup.open({
                items: {
                  src: globals.docPopUpForm + '?site_id=' + globals.siteID + "&language_code=" + globals.languageCode
                },
                mainClass: "mfp-doc-popup",
                type: 'ajax',
                modal:true,
                callbacks: {
                    ajaxContentAdded: function() {
                      // Ajax content is loaded and appended to DOM
                      $('.mfp-doc-popup').on('submit', 'form', function(e) {
                            e.preventDefault();
                            e.stopPropagation();
                            var $form = $(this);
                            //front-end validation if possible
                            var requiredFileds = $form.find('.is-required');
                            var requiredFieldsLength = requiredFileds.length;
                            var valid = true;
                            if(requiredFieldsLength) {
                                var i, $field;
                                for (i = 0; i<=requiredFieldsLength; i++) {
                                    $field = $(requiredFileds[i]);

                                    if($field.val() === '') {
                                        $field.addClass('required-field');
                                        valid = false;
                                    }
                                    else if ($field.attr('type') === 'email') {
                                        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                        if ( !re.test( $field.val() ) ) {
                                            $field.addClass('required-field');
                                            valid = false;
                                        } else {
                                            $field.removeClass('required-field');
                                        }
                                    }
                                    else {
                                        $field.removeClass('required-field');
                                    }
                                }
                            }

                             if (!valid) {
                                return false;
                             }

                            //prevent multisubmit check (if preventDefault fails)
                            if(typeof jQuery.data(this, "disabledOnSubmit") === 'undefined') {
                                jQuery.data(this, "disabledOnSubmit", true);
                            }
                            else {
                              return false;
                            }

                            //overlay
                            var $overlay = $('<div class="form-overlay" style="display:none;"><span></span></div>');
                            $form.append($overlay);
                            $overlay.fadeIn(200);

                            // matrix needs submit value to be sent...
                            var clonedSubmit = $form.find('.sq-form-submit').clone();
                            $form.append(clonedSubmit.attr('type', 'hidden'));

                            $(this).ajaxSubmit({

                                success: function(response) {
                                    $overlay.fadeOut(200);
                                    $('.mfp-content').html(response);

                                    window.dataLayer.push({'event':'Form Submission','formCategory':"Document Download",'formTitle':document.title,'eventCategory':'form submit'});

                                    //apply selectric
                                    if ($form.find('.sq-form-error').length) {
                                        $('.sq-form-error').siblings('.sq-form-question-answer').find('.sq-form-field').addClass('required-field');
                                    }
                                    else {
                                        Helpers.setCookie("data_collected", '1', 0, "/", "." + window.location.hostname);
                                        _self.docPopupFormClose($link);
                                    }
                                }
                            });
                            return false;
                        });
                    }
                }
              }, 0);
        },

        docPopupFormClose : function($element) {
            if ($element.hasClass('js-accept-technical-legal-info')) {
                $('.technical-library-legal-info').remove();
                $('.technical-library-container').removeClass('hidden');

                Helpers.setCookie("legalInfoAccept", '1', 0, "/", "." + window.location.hostname);

                Navigation();
            } else {
                window.open($element.attr('href'));
            }
        }
    };

    var closePopup = function() {
        
        if (localStorage.getItem('redirect-popup')) {
            //console.log('do not display popup');
            $('.popup-wrapper').remove();
        } else {
            //console.log('display popup');
            $('.popup-wrapper').addClass('revealed');
            $(document).on('click', '.js_close-popup', function(e){
                e.preventDefault();
                $('.popup-wrapper').removeClass('revealed').remove();
                localStorage.setItem('redirect-popup', 'seen');
            });
        }

    };

    // var assetListingLoadMore = function() {
    // var pageNumber = 1;
    // var buttonText = $(".capability-load-container .button-round--orange").html();
    //     $(".load-more-link").on('click touch', function() {
    //         pageNumber++;
    //         $.ajax({
    //             type: "GET",
    //             url: globals.capability.capabilityProductUrl,
    //             data: {
    //                 result_33318_result_page: pageNumber
    //             },
    //             success: function(data) {
    //                 var $data = $(data);
    //                 $data.find('.button-round--orange').append(buttonText);
    //                 $(".capability-load-container").append($data);
    //                 if ($(".asset-listing-last-page").length) {
    //                     $('.load-more-link').hide();
    //                 }
    //             }
    //         });
    //         return false;
    //     });
    // };

    // Main init
    var init = function() {

        cookieInfo();

        // Sliders initialisation
        if (Selectors.$sliderContainer.length) {
            Selectors.$sliderFor.slick(Sliders.main.settings);

            (Selectors.$sliderNav.find('img').length > 1) ?
                Selectors.$sliderNav.slick(Sliders.navForMain.settings)
                : Selectors.$sliderNav.remove();

            if (Selectors.$sliderNav.find('img').length > 1 && Selectors.$sliderNav.find('img').length < 5) {
                Sliders.SlideManager();
            }

            if (Selectors.$sliderFor.find('.video.youtube').length) {
                Sliders.ManageYoutube();
            }

            if (Selectors.$sliderFor.find('.video.vimeo').length) {
                Sliders.ManageVimeo();
            }
        }

        // Homepage Sliders

        if(Selectors.$featuredProductsCarousel.length) {
            // height fix for carousel
            Selectors.$featuredProductsCarousel.on('setPosition', function(s) { console.log('reinit');
                Selectors.$featuredProductsCarousel.find('.slick-slide').height('auto');
                Selectors.$featuredProductsCarousel.find('.slick-track').addClass('column-items').css('display', 'flex');
            });
            // init carousel
            Selectors.$featuredProductsCarousel.slick(Sliders.featuredProductsCarousel.settings);
        }
        if(Selectors.$customSliderWrapper.length) {
            Selectors.$customSliderWrapper.slick(Sliders.customSliderWrapper.settings);
        }

        //hero Images slider
        if(Selectors.$heroImageSlider.length) {
            Selectors.$heroImageSlider.slick(Sliders.heroImageSlider.settings);
            Selectors.$heroImageSlider.slick('slickPlay');
            buttonManager.homePageHeroVideoPlayer();
        }
        
        if(Selectors.$capabilityImageSlider.length) {
            Selectors.$capabilityImageSlider.slick(Sliders.capabilitySlider.settings);
        }

        // Truncation
        if(Selectors.$truncatedTextContainer.length) {
            buttonManager.truncation();
        }

        // Navigation
        if(document.getElementById('sectionNav') !== null && Selectors.$sectionNav.is(':visible')) {
            // entire navigation functionality
            Navigation();
        }

        // Buttons
        buttonManager.showMoreRelatedItems();
        //buttonManager.shwotruncatedText();
        buttonManager.buyHireBoxManger();
        buttonManager.headerPopupToggle();
        buttonManager.mainMenuToggle();
        buttonManager.mainMenuToggleMobile();
        buttonManager.bodyClick();
        buttonManager.headerSearchToggle();
        //buttonManager.productListToggle();

        if ($(Selectors.$acceptTechnicalLegalInfo).length) {
            buttonManager.acceptTechnicalLegalInfo();
        }

        //Location switch
        buttonManager.switchLocation();

        // recently viewed

        if(Selectors.$recentlyViewedContainer.length) {
            // init recently viewed
            recentlyViewed(globals.assetID, globals.recentlyViewedListingURL);
        }


        //Google Map
        if ($(Selectors.genericPageGooleMap)) {
            genericPageMap();
        }

        //wrap Matrix table into responsive wrapper
        Helpers.tableWrapper();

        //adds selectic wraper to select item with .selectric-here class
        Helpers.selectricLauncher();

        //Handle custom forms if exist
        if(Selectors.$customFormContainers.length) {
            CustomForms();
        }

        if(Selectors.$showContactForm.length) {
            ContactForm();
        }

        if(Selectors.$filterButton.length) {
            toggleFilter();
        }

        //product page contact info box
        if (Selectors.$buyHireConatainer.length) {
            getContactBoxInfo();
        }

        if(Selectors.$newsSelect.length) {
            newsFilter();
        }

        if ($("#search-results").length) {
            searchPage();
        }

        if ($("#news-results").length) {
            newsAggregator();
        }

        if ($(docDowloadManager.sel.downloadLink).length) {
            docDowloadManager.init();
        }

        //Capability select from homeage
        buttonManager.goToCapabilityPage();

        //  if ($(".capability-load-container").length) {
        //     assetListingLoadMore();
        // }
        
        if ($('.js_close-popup').length) {
            closePopup();
        }

    };



    // make it revealed
    return {
        init: init
    };

})();


$(document).on('ready', function(){

/*
* Main functionality init
*/
    Mabey.init();
  

});

/*
--------------------
Modules
--------------------
*/
